.tab-content{
    background-color: transparent!important;
}

.custom-wrapper{
    border:solid 1px #cdcdcd;

}
.custom-wrapper-comparacion{
    border:solid 1px #cdcdcd;
}

.rdrDefinedRangesWrapper {
    width:320px;
  }
  
/* Estilos para cada columna de botones */
.rdrStaticRanges {
    flex-flow: row wrap;
}
.defined-range-panel {
    display: none;
  }

/* Estilos para cada botón estático */
.rdrStaticRange {
    flex-basis:50%;
    height: 30px;    
}
span.rdrStaticRangeLabel{
    padding-top:0;
    padding-bottom: 0;
    line-height: 30px;    
}

#kpi-tooltip{
    width: 350px!important;
    white-space: nowrap;
    padding: 10px;
}


#kpi-tooltip > div.tooltip-inner{

    max-width: 100%;

}